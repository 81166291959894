import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import styled from "styled-components"
import { whr_blue } from "../assets/colours"

const ResourcesGrid = () => {
  const data = useStaticQuery(graphql`
    {
      allWpResource(sort: { fields: title, order: ASC }) {
        edges {
          node {
            id
            title
            resourceFields {
              resourceUrl
            }
          }
        }
      }
    }
  `)

  const [filter, setFilter] = useState("")

  const resources = data.allWpResource.edges.map(resource => {
    return {
      title: resource.node.title.toLowerCase(),
      link: resource.node.resourceFields.resourceUrl,
    }
  })

  return (
    <Resources>
      <input
        value={filter}
        type="text"
        onChange={event => setFilter(event.target.value)}
        placeholder="Search our resources..."
      />
      {resources
        .filter(
          resource =>
            resource.title.includes(filter.toLowerCase()) || filter === ""
        )
        .map((resource, index) => {
          return (
            <a
              key={index}
              target="_blank"
              rel="noreferrer"
              href={resource.link}
            >
              <span className="resource">{resource.title}</span>
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-journal-check"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                  />
                  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                </svg>
              </span>
            </a>
          )
        })}
    </Resources>
  )
}

export default ResourcesGrid

const Resources = styled.div`
  display: flex;
  flex-wrap: wrap;

  input[type="text"] {
    width: 100%;
    margin-bottom: 48px;
    font-family: "Karla";
    font-size: 24px;
    border: 0;
    padding-bottom: 8px;
    border-bottom: 2px solid darkgrey;
  }

  a {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
    font-family: "Bebas Neue", sans-serif;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid ${whr_blue};
    color: ${whr_blue}!important;
    border-radius: 0;
    font-size: 18px;
    transition: 0.7s;
    text-decoration: none;
    letter-spacing: 1px;

    &:hover {
      background: ${whr_blue};
      color: white !important;
      transition: 0.7s;
    }

    span {
      &.resource {
        padding: 8px 0px 8px 8px;
        margin-right: 24px;
      }
      &.icon {
        background: ${whr_blue};
        border-radius: 0;
        margin-left: 8px;
        display: flex;
        height: 100%;
        justify-content: center;
        padding: 12px;
        transition: 0.7s;
        align-items: center;

        * {
          color: white;
        }
      }
    }
  }
`
