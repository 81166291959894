import React, { useState } from "react"
import { content, content_inner, featured_image } from "../assets/global"

import Image from "gatsby-image"
import Layout from "../components/layout"
import PageHeader from "../components/page_header"
import QuickMenu from "../components/quick_menu"
import ResourcesGrid from "../components/resources_grid"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import sanitizeHtml from "sanitize-html"
import styled from "styled-components"

const ResourcesPage = ({ data, location }) => {
  const featuredImage = {
    fluid: data.wpPage.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: data.wpPage.featuredImage?.node?.alt || ``,
  }

  const menu_links = [
    {
      title: "About our resources",
      id: "about",
    },
    {
      title: "Advocacy",
      id: "advocacy",
    },
    {
      title: "Continuing Professional Development",
      id: "continuing-professional-development",
    },
    {
      title: "Resource Library",
      id: "library",
    },
  ]

  const [menuOpen, setMenuOpen] = useState(false)
  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  return (
    <Layout
      title={data.wpPage.seo.title}
      menuOpen={menuOpen}
      toggleMenu={toggleMenu}
      fullWidthMenu={false}
    >
      <Seo
        description={data.wpPage.seo.metaDesc}
        title={data.wpPage.seo.title}
      />
      <Resources>
        <FeaturedImage>
          <Image
            fluid={featuredImage.fluid}
            alt={featuredImage.alt}
            style={{ height: "100%" }}
          />
        </FeaturedImage>
        <Content>
          <PageHeader title={data.wpPage.title}></PageHeader>
          <QuickMenu jump_text="Jump To" menu_items={menu_links}></QuickMenu>
          <ContentInner>
            <section id="about">
              <h1>About our resources</h1>
              <Intro
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    data.wpPage.pageTemplateResources.resourcesIntro
                  ),
                }}
              ></Intro>
            </section>
            <section id="advocacy">
              <h1>Advocacy</h1>
              <article
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    data.wpPage.pageTemplateResources.advocacyIntro
                  ),
                }}
              ></article>
            </section>
            <section id="continuing-professional-development">
              <h1>Continuing Professional Development</h1>
              <article
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    data.wpPage.pageTemplateResources.cpdIntro,
                    {
                      allowedTags: [
                        "a",
                        "h1",
                        "h2",
                        "h3",
                        "h4",
                        "h5",
                        "h6",
                        "p",
                        "strong",
                        "img",
                        "ul",
                        "li",
                      ],
                      allowedAttributes: {
                        a: ["style", "href", "target", "rel"],
                        ul: ["style"],
                      },
                    }
                  ),
                }}
              ></article>
            </section>
            <section id="library">
              <h1>Resources Library</h1>
              <ResourcesGrid></ResourcesGrid>
            </section>
          </ContentInner>
        </Content>
      </Resources>
    </Layout>
  )
}

const Content = styled.div`
  ${content};
`

const Intro = styled.div`
  p {
    font-family: "Karla", sans-serif;
  }
`

const FeaturedImage = styled.div`
  ${featured_image};
`

const ContentInner = styled.div`
  ${content_inner};
`

const Resources = styled.div``

export default ResourcesPage

export const pageQuery = graphql`
  {
    wpPage(template: { templateName: { eq: "Resources Template" } }) {
      seo {
        metaDesc
        title
      }
      id
      title
      slug
      pageTemplateResources {
        resourcesIntro
        advocacyIntro
        cpdIntro
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
